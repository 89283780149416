/* eslint-disable no-restricted-globals */
import React, { useEffect } from "react";
import firebase from "../firebase";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { 
  useStripe, 
} from "@stripe/react-stripe-js";

import { toastr } from 'react-redux-toastr'; 
import { API_URL } from "../constant";
import CheckoutForm from "./checkoutForm";
import { Badge } from "react-bootstrap";
import moment from "moment";
 
const stripePromise = loadStripe("pk_live_51DYygZHKNIdygvuQfapHDILCsMAIBdW7q5y6yoLjtboAvrOYxiN6tL1ZHksFfYwGTiRnafL0Xlp3BwyX4ruAjWxR00LLfr73ug")//"pk_test_aRHx5mse9Y6hBWLYZS1sfVFB");
const Panier = () => {  
      
    const stripe = useStripe();
  const selectingPack = localStorage.getItem("selectingPack");
  
  
    const db = firebase.firestore();
    const history = useHistory();
    const [loading, setLoading] = React.useState(false);
    const [clientSecret, setClientSecret] = React.useState('');
    const [paniers, setPanier] = React.useState([]);
    const [loadingUser, setLoadingUser] = React.useState(false);
    const [livraison, setLivraison] = React.useState(true);
    const [isprepanier, setIsprePanier] = React.useState(false)
    const [loadingForm1, setLoadingForm1] = React.useState(false);
    const [hideupdatedel, setHideUpdateDel] = React.useState(false);
    const [code,setCode] = React.useState(null);
    const [codeErr,setCodeErr] = React.useState(false);
    const [myCodes, setMyCodes] = React.useState([]);
    const [configlivraison, setCL] = React.useState(null);
    const [selectPack, setSelectPack] = React.useState(null);
    const appearance = {
      theme: 'stripe',
    };
    const options = {
      clientSecret,
      appearance,
    };
     

    const getConfig = async ( ) => {
      await db
        .collection("config")
        .doc('livraisons')
        .get()
        .then(async (docd) => {
          var livraison = docd.data();
          livraison.id = docd.id;
          setCL(Number(livraison?.price/100)); 
        }) 
    };
 

    const { register, handleSubmit, setValue, getValues } = useForm({
      mode: "onSubmit",
      reValidateMode: "onChange",
      defaultValues: {},
      resolver: undefined,
      context: undefined,
      criteriaMode: "firstError",
      shouldFocusError: true,
      shouldUnregister: false,
      shouldUseNativeValidation: false,
      delayError: undefined,
    });


    


      const  groupBy = (xs, key) => {
        return xs.reduce((rv, x)=> {
          // eslint-disable-next-line no-param-reassign
          (rv[x[key]] = rv[x[key]] || []).push(x);
          return rv;
        }, {});
        };

      const getPanier = async (uid, cb) => {
        await db
          .collection("carts")
          .doc(uid)
          .get()
          .then(async (docd) => {
            if (docd.exists){
              var cart = docd.data();
              cart.id = docd.id;
              cb(cart);
            }else{
              cb(null)
            }
          })
          .catch((error) => {
            console.log("getPanier error", error);
            cb(null);
          });
  
      }

      const pPanier = (p) => { 
        const dss = Object.entries(groupBy(p, 'title')); 
        return dss;
      }
  
      const getQte = (data) => {
        let total = 0 
        data?.forEach(d => {
          total +=  Number(d.quantity);
        });
        return total;
      }
 

      useEffect(() => {
        const payment_intent_client_secret = new URLSearchParams(window.location.search).get(
          "payment_intent_client_secret"
        );
        if (paniers && payment_intent_client_secret){ 
          setClientSecret(payment_intent_client_secret);
        }else{
          if (paniers && paniers?.status === 'wainting_pay'){
            // console.log("ici")
            setLoading(true)
            // Create PaymentIntent as soon as the page loads
            fetch(`${API_URL}create-payment-intent`, {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({ panierId: paniers.id }),
            })
              .then((res) => res.json())
              .then((data) => { 
                setLoading(false)
                // console.log("setClientSecret",data?.clientSecret)
                setClientSecret(data?.clientSecret);
              }).catch(err=> {
                setLoading(false)
                console.error("error",err)
              })
          }
        }
        
      }, [paniers])

      const loadPanier = () => {
        setLoadingUser(true);
        firebase.auth().onAuthStateChanged((u) => {
          if (u) { 
            setLoadingUser(true); 
            getPanier(u.uid, panier => {
                if (panier && panier.products && panier?.products?.length > 0){
                    // console.log("panier", panier);
                    setPanier(panier);
 
                }else{ 
                    
                    history.push("/");
                    // alert("Aucun produit au panier");

                    toastr.error('Aucun produit au panier');
                }
            })
          } else {
            const puid = localStorage.getItem("pre-panier");
            if (puid) {
              getPanier(puid, panier => { 
                if (panier && panier.products && panier?.products?.length > 0){
                    // console.log("panier", panier); 
                    setIsprePanier(true)
                    setPanier(panier);
                }else{ 
                    
                    history.push("/");
                    // alert("Aucun produit au panier");

                    toastr.error('Aucun produit au panier');
                }
                setLoadingUser(false);
              })
            }else{ 
              history.push("/");
            }
            
          }
        });
        setTimeout(() => {
          setLoadingUser(false);
        }, 5000);

      }
      
    useEffect(()=>{
      getConfig()
        loadPanier()
    }, [])



    function calculerPrixTotal(obj, quantite) {  
      //Vérifier s'il existe un pack exact pour la quantité demandée
      const packExact = obj?.packs.find(pack => pack.nbr === quantite);
      if (packExact) return (packExact.nbr * packExact.price) / 100; // Total pour le pack en euros
  
          // Vérifier si la quantité se situe entre deux packs pour choisir le plus proche
      for (let i = 0; i < obj?.packs.length - 1; i++) {
        if (quantite >= obj.packs[i].nbr && quantite < obj.packs[i + 1].nbr) {
            return (quantite * obj.packs[i].price) / 100; // Total pour le pack en euros
        }
      }


      let pk = obj?.packs[obj?.packs.length - 1];
      if (pk && quantite > pk?.nbr){ 
        return (pk.price * quantite) / 100;
      } 


      // Sinon, calculer le prix par unité selon le tableau `pricePer`
      let prixParQuantite;
      for (let i = obj?.pricePer.length - 1; i >= 0; i--) {
          if (quantite >= obj.pricePer[i].nbr) {
              prixParQuantite = (obj.pricePer[i].price * quantite) / 100; // Total pour `pricePer` en euros
              break;
          }
      }
  
      return prixParQuantite || "Quantité non prise en charge";
  }


  function getpacks(obj, quantite) {
    // Vérifier s'il existe un pack exact pour la quantité demandée
      const packExact = obj?.packs.find(pack => pack.nbr === quantite); 
      if (packExact) return packExact.price / 100
      // if (packExact) return (packExact.nbr * (packExact.price/100)) / 100; // Total pour le pack en euros
      // Vérifier si la quantité se situe entre deux packs pour choisir le plus proche
      for (let i = 0; i < obj?.packs.length - 1; i++) {
        if (quantite >= obj.packs[i].nbr && quantite < obj.packs[i + 1].nbr) {
            return obj.packs[i].nbr ; // Total pour le pack en euros
        }
      }


      let pk = obj?.packs[obj?.packs.length - 1];
      if (pk && quantite > pk?.nbr){ 
        return pk.nbr;
      }

      return null
    }


    function calculerPrixunitaire(obj, quantite) {
      // Vérifier s'il existe un pack exact pour la quantité demandée
      const packExact = obj?.packs.find(pack => pack.nbr === quantite); 
      if (packExact) return packExact.price / 100
      // if (packExact) return (packExact.nbr * (packExact.price/100)) / 100; // Total pour le pack en euros
      // Vérifier si la quantité se situe entre deux packs pour choisir le plus proche
      for (let i = 0; i < obj?.packs.length - 1; i++) {
        if (quantite >= obj?.packs[i].nbr && quantite < obj.packs[i + 1].nbr) {
            return (obj.packs[i].price) / 100; // Total pour le pack en euros
        }
      }
      let pk = obj?.packs[obj?.packs.length - 1];
      if (pk && quantite > pk?.nbr){ 
        return (pk.price) / 100;
      } 

      // // Sinon, calculer le prix par unité selon le tableau `pricePer`
      let prixParQuantite = 0;
      for (let i = obj?.pricePer.length - 1; i >= 0; i--) {
          if (quantite >= obj.pricePer[i].nbr) {
              prixParQuantite = obj.pricePer[i].price / 100; // Total pour `pricePer` en euros
              break;
          }
      }

      return prixParQuantite;
  }


    const LineItem = ({ p, index }) => {
      const [l, setL] = React.useState(false); 

 
      return (
        <tr>
          <th scope="row">
            {p[1][0]?.upName && `${p[1][0]?.upName} `}
            {p[0]} 
            {getpacks(p[1][0].type, getQte(p[1])) != null && ` (Packs ${getpacks(p[1][0].type, getQte(p[1]))} photos)`}
  
          </th> 
          <td>
            <div class="form-group mb-0">
            <p style={{ textAlign: "left" }}>{getQte(p[1])}</p>  
            </div>
          </td>
          <td>
          <>{calculerPrixunitaire(p[1][0].type, getQte(p[1])).toFixed(2)} €</> 
            </td>
          <td class="text-right">
            {calculerPrixTotal(p[1][0].type, getQte(p[1])).toFixed(2)}€ 
          </td>
          <td>
          <button className="btn btn-danger btn-xs" onClick={() => {
            if (l){
              return; 
            }
            setL(true) 
            let ds = [];
            paniers?.products.forEach((d)=>{
              if (d?.title != p[0]){
                ds.push(d);
              }
            }) 
            firebase.firestore()
              .collection("carts")
              .doc(paniers.user.id)
              .update({
                products:ds
              })
              .then(async () => { 
                let n = paniers
                n.products = ds;
                if (ds.length === 0){
                  alert("Votre panier est vide");
                  history.push("/");
                }
                setPanier([])
                setTimeout(() => {
                  setPanier(n);
                  setL(false)
                }, 200);
              })
              .catch((error) => {
                console.error("error", error)
                alert("Une erreur s'est produite");
                window.location.reload()
                setL(false)
              });

          }}>
            {!l ? <i class="fa fa-trash"/> : 'Chargement ...'}
          </button>
          </td>
        </tr>
      );
    }
    function subN() {

      let sub = 0; 
      if (paniers && paniers?.products){
        pPanier(paniers?.products).forEach((d, i) => { 
          sub += calculerPrixTotal(d[1][0].type, getQte(d[1]))
        })
      } 
      return (sub * 0.014).toFixed(2);
    };
    
    function subNt(has = false) { 


      let sub = 0; 
      if (paniers && paniers?.products){
        pPanier(paniers?.products).forEach((d, i) => { 
          sub += calculerPrixTotal(d[1][0].type, getQte(d[1]))
        })
      } 
      if (has && paniers?.codepromo){
        let code = paniers?.codepromo;
        return Number(Number(sub + sub * 0.014) - (code?.percent ? (code.reduc/100) * (Number(sub + sub * 0.014))  : (code.reduc))).toFixed(2)
      }
      return Number(sub + sub * 0.014).toFixed(2)
    }

    function sub() {

      let sub = 0; 
      if (paniers && paniers?.products){
        pPanier(paniers?.products).forEach((d, i) => { 
          sub += calculerPrixTotal(d[1][0].type, getQte(d[1]))
        })
      }


      return sub.toFixed(2); 
    };

    const getCodes = async () => {  
      let codes =[]
      await db
            .collection("codes")   
            .where('delete', '==', false)
            .get()
            .then(async (querySnapshot) => {
              querySnapshot.forEach((doc) => {
                var order = doc.data();
                order.id = doc.id;
                codes.push(order)
              }) 
              setMyCodes(codes); 
            })
            .catch((error) => {  
              console.error(error);
            });
  }

    const pay  = (value) =>  {
      // enregistrer les informations et direction page de paiement stripe.

      if (loadingForm1) {
        return;
      }
      // console.log('value', value)
      let codepromo = null;
      if (value.codepromo && code != null){
        // on ajoutera le codepromo
        codepromo = code;
      } 
    setLoadingForm1(true);

    let s = JSON.parse(selectingPack);
    firebase.firestore()
    .collection("carts")
    .doc(paniers.user.id)
    .update({
      client: value,
      codepromo: codepromo,
      status: 'wainting_pay',
      livraison: livraison,
      packs : s ? s : null
    }).then(async () => {
      setLoadingForm1(false);
      var np = paniers;
      np.client = value;
      np.status = 'wainting_pay';
      np.codepromo  = codepromo
      setPanier(np);
      // console.log('panier', np)
      setLoading(true)
      window.location.reload();
      // fetch(`${API_URL}create-payment-intent`, {
      //   method: "POST",
      //   headers: { "Content-Type": "application/json" },
      //   body: JSON.stringify({ panierId: paniers.id }),
      // })
      //   .then((res) => res.json())
      //   .then((data) => { 
      //     setLoading(false)
      //     // console.log("setClientSecret",data?.clientSecret)
      //     // setClientSecret(data?.clientSecret);
      //     window.location.reload();
      //   }).catch(err=> {
      //     setLoading(false)
      //     console.error("error",err)
      //   })
      // window.location.reload()
     }).catch((error) => {
      alert("Une erreur s'est produite");
      window.location.reload() ;
      setLoadingForm1(false);
    });


    }

    const getUser = async (uid, cb) => {
      await firebase.firestore()
        .collection("users")
        .doc(uid)
        .get()
        .then(async (docd) => {
          var user = docd.data();
          user.id = docd.id;
          cb(user);
        })
        .catch((error) => {
          cb(null);
        });
    };

    useEffect(() => {
      if (paniers && paniers?.products?.length > 0 && paniers?.status !== 'wainting_pay'){
        
        getCodes()
        firebase.auth().onAuthStateChanged((u) => {
          if (u) { 
            //redirection au compte
            getUser(u.uid, (user) => { 
              // console.log("user", user);
              setValue("phoneperso", user.phoneperso || "");
              setValue("lastName", user.nom);
              setValue("firstName", user.prenom);
              setValue("citypersolocation", user?.citypersolocation || "");
              setValue("addresspersolocation", user?.addresspersolocation || ""); 
              setValue("complementpersolocation", user?.complementpersolocation || ""); 
              setValue("countrypersolocation", user?.countrypersolocation || ""); 
              setValue("statepersolocation", user?.statepersolocation || ""); 
              setValue("zipcodepersolocation", user?.zipcodepersolocation || "");  
            });
          }  
        });
      }
    }, [paniers])

     
  useEffect(() => { 

    if (!stripe) {
      return;
    }

    
    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    } 
    // cacher les boutons
    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      // console.log('paymentIntent.status here', paymentIntent.status);
      if (paymentIntent?.status === "succeeded"){
        setHideUpdateDel(true)
      }
      // switch (paymentIntent.status) {
      //   case "succeeded":
      //     setMessage("Paiement réussi !");
      //     goToOrderPage();
      //     toastr.success('', 'Paiement réussi !');
      //     break;
      //   case "processing":
      //     setMessage("Votre paiement est en cours de traitement.");
      //     toastr.success('', 'Votre paiement est en cours de traitement.');
      //     break;
      //   case "requires_payment_method":
      //     setMessage("Votre paiement n'a pas abouti, veuillez réessayer.");
      //     toastr.error('', "Votre paiement n'a pas abouti, veuillez réessayer.");
      //     break;
      //   default:
      //     setMessage("Quelque chose s'est mal passé");
      //     toastr.error('', "Quelque chose s'est mal passé");
      //     break;
      // }
    });
  }, [stripe]);

  const estEntreDates = (dateDebut, dateFin, dateActuelle) => {
    return dateActuelle >= dateDebut && dateActuelle <= dateFin;
  };
  
  // Fonction pour vérifier si la date actuelle est égale à l'une des dates
  const estEgaleAUneDate = (date, dateActuelle) => {
    return dateActuelle.getTime() === date.getTime();
  };

  

    function checkCode (code, cb)  {
      setCode(null)
      setCodeErr(false)
      // console.log("code", code)
      let res = null
      myCodes?.forEach((objet) => {
        // console.log('p', p)
        if(objet.code === code){
          const a = moment().isBetween(moment(objet.datestart?.toDate()), moment(objet.dateend?.toDate()))
          if (a){
            res = objet
          }
        }  
         
        
      })
      console.log("resultat", res)
      cb(res)
    }

    return ( 
        <>
        <div class="container">
        <h1 class="mt-5">{paniers && paniers?.status === 'wainting_pay' ? "Paiement" : "Panier"}</h1> 
        <div class="row mb-5" style={{textAlign:"left"}}>
          {paniers.length === 0 && (
            <p style={{textAlign:'center'}}>Chargement ...</p>
          )}

          {paniers && paniers?.products?.length > 0 && (Number(sub()) < 5) && (
            <div class="alert alert-danger" role="alert">
            Le montant total (hors livraison) est inférieur au montant minimum requis pour passé commande (5€) vous pouvez directement en magasin afin d'effectuer vos impressions avec vos photos.
          </div> 
          )}

          {paniers && paniers?.products?.length > 0 && paniers?.status !== 'wainting_pay' ?  (

          <div class="card" style={{ padding: 20, marginBottom: 30 }}>
          <div class="cart-head">
            <div class="table-responsive">
              <table class="table table-borderless">
                <thead>
                  <tr>  
                    <th scope="col">Produit</th>
                    <th scope="col">Qté</th>
                    <th scope="col">Prix</th>
                    <th scope="col" class="text-right">
                      Total
                    </th>
                  </tr>
                </thead>
                <hr style={{background:'grey', height: 1, width: '100%'}}/>
                <tbody> 
                {paniers && paniers?.products?.length > 0 && pPanier(paniers?.products).map((p, i)=> {
                  return  <LineItem p={p} index={i} />
              })}
                  
                </tbody>
              </table>
              {paniers && paniers?.products?.length > 0 && (
                <div class="col-md-12 order-1 order-lg-2 col-lg-7 col-xl-6">
                  <div class="order-total table-responsive ">
                    <table class="table table-borderless text-right">
                      <tbody>
                        <tr>
                          <td>Sous total :</td>
                          <td>{sub()} €</td>
                        </tr>
                        <a href="#" style={{paddingLeft:10, fontSize: 11}} onClick={() => (setLivraison(!livraison))}>
                          Je {livraison && "ne"} souhaite {livraison && "plus"} la livraison pour cette commande
                        </a>
                        {livraison ? (
                          <>
                            <tr>
                              <td>
                                <b>Livraison + suivis (3 jours)</b>
                                :
                              </td>
                              {configlivraison.toFixed(2)} € 
                            </tr> 
                            <span style={{ fontSize: 10 }}>Les estimations de livraison tiennent compte des jours non ouvrables, tels que les week-ends et les jours fériés.</span> 
                          </>
                        ) : (
                          <>
                          <tr>
                              <td>
                                <b>Frais de services</b>
                                :
                              </td>
                              {subN()} € 
                            </tr> 
                          </>
                        )}

                        {/* <tr>
                          <td>
                            Frais{" "}
                            <span style={{ fontSize: 10 }}>({taxpercent()})</span> :
                          </td>
                          <td>$180.00</td>
                        </tr> */}
                        <tr>
                          <td class="f-w-7 font-18">
                            <h4>Total (TTC) :</h4>
                          </td>
                          <td class="f-w-7 font-18">
                          <td><b>{livraison ?  (Number(sub()) + configlivraison).toFixed(2)  : subNt()} €</b></td>
                          </td>
                        </tr> 
                        <a class="btn btn-xs btn-primary" style={{textAlign:'end', borderColor: "white", paddingBottom: 5}} href="/c/all">
                          Rajouter des photos
                        </a> 
                        
                      </tbody>
                    </table>
                  </div>
                </div>
                )}
              </div>
            </div>


            </div>
            ) : (
              <>{paniers &&  paniers.length > 0 && (
                <p style={{display:"flex", flexDirection: "row", justifyContent:"space-between"}}><a href="#" onClick={() => {
                   
                    firebase.firestore()
                    .collection("carts").doc(paniers.user.id).update({
                      status: null,
                    }).then(() => {
                      setPanier([])
                      loadPanier()
                    }) 
                }}>Modifier le panier</a> <a href="#" onClick={() => {
                  if (confirm("Annuler et vider le panier ?")){
                    firebase.firestore()
                    .collection("carts").doc(paniers.user.id).delete().then(() => {
                      toastr.success("Le panier a été supprimer.")
                      history.push("/");
                    })
                  }
                }}>Annuler et vider le panier</a></p>
              )}
                </>
            )}
       
        <div>  
          {isprepanier && (<a href="/connexion" onClick={() => {
            localStorage.setItem("redirectAfterlogin", window.location.href);
          }}><p style={{textAlign:'center'}}>Inscrivez vous ou connectez vous pour commander</p></a>)}
        
        
        {Number(sub()) >= 5 && !isprepanier && paniers && paniers?.products?.length > 0 && paniers?.status !== 'wainting_pay' &&  (

            <form
            class="needs-validation"
            novalidate
            onSubmit={handleSubmit(pay, (err) => {
              console.log(err);
            })}
            >
            <div class="row g-3">

            <div class="col-sm-6">
                <label for="codepromo" class="form-label">
                Code promotionnelle (sensible à la case*)
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="codepromo"
                  placeholder="Code promotionnelle" 
                  onChange={(e) =>
                    {
                      // console.log('change', e.target.value);
                    checkCode(e.target.value, (r) => { 
                      if (r === null){
                        setValue("codepromo", null) 
                        // console.log("r", r)
                        setCode(null)
                        setCodeErr(true)
                      }else{
                        setValue("codepromo", e.target.value) 
                        // console.log("r", r)
                        setCodeErr(false)
                        setCode(r)
                      }
                        
                    })
                    // 
                    }
                  } 
                /> 
              </div>
              <div class="col-sm-6">
                  {code != null && code?.code != null && <p style={{marginTop:40}}><i class="fa fa-check-circle" style={{color:'green', marginRight:10}}/>Votre code est valide <Badge bg={code?.percent ? 'success': 'warning'} >{code.reduc} {code?.percent ? '%': '€'}</Badge></p>}
                  {codeErr && code === null && <p style={{marginTop:40}}><i class="fa fa-exclamation-triangle" style={{color:'red', marginRight:10}}/>Votre code est incorrect <Badge bg={'danger'} >Invalide</Badge></p>}
              </div>

              <div class="col-sm-6">
                <label for="firstName" class="form-label">
                  Prénom
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="firstName"
                  placeholder=""
                  required
                  onChange={(e) =>
                    setValue("firstName", e.target.value)
                  }
                  {...register("firstName")}
                />
                <div class="invalid-feedback">
                  Prénom est requis
                </div>
              </div>

              <div class="col-sm-6">
                <label for="lastName" class="form-label">
                  Nom 
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="lastName"
                  placeholder=""
                  name="lastName"
                  required
                  onChange={(e) =>
                    setValue("lastName", e.target.value)
                  }
                  {...register("lastName")}
                />
                <div class="invalid-feedback">
                  Un Nom de famille valide est requis
                </div>
              </div>

              <div class="col-12">
                <label for="phone" class="form-label">
                  Téléphone{" "}
                  <span class="text-muted">(obligatoire)</span>
                </label>
                <input
                required
                  type="phone"
                  class="form-control"
                  id="phone"
                  placeholder="Téléphone personnelle"
                  onChange={(e) =>
                    setValue("phoneperso", e.target.value)
                  }
                  {...register("phoneperso")}
                />
              </div> 
            </div>    
            <hr/>
                <div class="row g-3">
                  <div class="col-12">
                    <label
                      for="addresslocation"
                      class="form-label"
                    >
                      Adresse
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="addresslocation"
                      placeholder="1234 Main St"
                      required
                      onChange={(e) =>
                        setValue(
                          "addresspersolocation",
                          e.target.value
                        )
                      }
                      {...register("addresspersolocation")}
                    />
                    <div class="invalid-feedback">
                      Veuillez entrer une adresse valide pour la
                      livraison / facturation
                    </div>
                  </div>

                  <div class="col-12">
                    <label
                      for="address2location"
                      class="form-label"
                    >
                      Adresse 2{" "}
                      <span class="text-muted">
                        (facultative)
                      </span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="address2location"
                      placeholder="Appartement et complément d'adresse"
                      onChange={(e) =>
                        setValue(
                          "complementpersolocation",
                          e.target.value
                        )
                      }
                      {...register("complementpersolocation")}
                    />
                  </div>

                  <div class="col-md-6">
                    <label
                      for="countrylocation"
                      class="form-label"
                    >
                      Pays
                    </label>
                    <select
                      class="form-select"
                      id="countrylocation"
                      required
                      onChange={(e) =>
                        setValue(
                          "countrypersolocation",
                          e.target.value
                        )
                      }
                      {...register("countrypersolocation")}
                    >
                      <option value="">Choisir...</option>
                      <option value="France">France</option>
                    </select>
                    <div class="invalid-feedback">
                      Please select a valid country.
                    </div>
                  </div>

                  <div class="col-md-6">
                    <label
                      for="statelocation"
                      class="form-label"
                    >
                      Etat / région
                    </label>
                    <select
                      class="form-select"
                      id="statelocation"
                      required
                      onChange={(e) =>
                        setValue(
                          "statepersolocation",
                          e.target.value
                        )
                      }
                      {...register("statepersolocation")}
                    >
                      <option value="">Choisir...</option> 
                      <option value="île de la Réunion">
                        Île de la Réunion
                      </option>
                      <option value="France">
                        France Métropolitaine
                      </option>
                    </select>
                  </div>
                  <div class="col-md-6">
                    <label
                      for="citylocation"
                      class="form-label"
                    >
                      Ville
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="citylocation"
                      name="citylocation"
                      required
                      onChange={(e) =>
                        setValue(
                          "citypersolocation",
                          e.target.value
                        )
                      }
                      {...register("citypersolocation")}
                    />
                  </div>

                  <div class="col-md-6">
                    <label for="ziplocation" class="form-label">
                      Code postale
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="ziplocation"
                      maxLength={5}
                      placeholder=""
                      required
                      onChange={(e) =>
                        setValue(
                          "zipcodepersolocation",
                          e.target.value
                        )
                      }
                      {...register("zipcodepersolocation")}
                    />
                    <div class="invalid-feedback">
                      Zip code required.
                    </div>
                  </div>

                  <div class="col-12">
                    <label
                      for="note"
                      class="form-label"
                    >
                      Note
                      <span class="text-muted">
                        (facultative)
                      </span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="note"
                      placeholder="Note pour ZePixBox"
                      onChange={(e) =>
                        setValue(
                          "note",
                          e.target.value
                        )
                      }
                      {...register("note")}
                    />
                  </div>
                </div> 
            <hr class="my-4" />

            <button
              class="w-100 btn btn-success btn-lg"
              type="submit"
              disabled={loadingForm1}
            >
              {loadingForm1
                ? "Chargement ...."
                : "Continuer vers le paiement"}
            </button>
            <span style={{textAlign:10}}>* Les caractères du champs prenne en compte les majuscules et les minuscules.</span>
            </form>


        )}


        {!loading ? (
          <>
          {
            paniers && paniers?.products?.length > 0 && paniers?.status === 'wainting_pay' &&  (
                      <> 
                        {paniers && paniers?.products?.length > 0 && (
                            <div class="col-md-12  ">
                              <div class="order-total table-responsive ">
                                <table class="table table-borderless text-right">
                                  <tbody>
                                    <tr>
                                      <td>Sous total :</td>
                                      <td>{sub()} €</td>
                                    </tr> 
                                    {paniers.livraison ? (
                                      <>
                                        <tr>
                                          <td>
                                            <b>Livraison + suivis (3 jours)</b>
                                            :
                                          </td>
                                          {configlivraison.toFixed(2)} € 
                                        </tr> 
                                        <span style={{ fontSize: 10 }}>Les estimations de livraison ne tiennent pas compte des jours non ouvrables, tels que les week-ends et les jours fériés.</span> 
                                      </>
                                    ): (
                                      <>
                                        <tr>
                                            <td>
                                              <b>Frais de services</b>
                                              :
                                            </td>
                                            {subN()} € 
                                          </tr> 
                                        </>
                                    )}

                                    {paniers?.codepromo && (
                                        <tr>
                                            <td>
                                              <b>Réduction ({paniers?.codepromo?.percent ? `${paniers?.codepromo.reduc}%`: `${paniers?.codepromo.reduc} €`})</b>:
                                            </td>
                                            <td style={{color:'orange', fontWeight:'bold'}}>-{paniers?.codepromo?.percent ? paniers.livraison ? 
                                             Number(Number(paniers?.codepromo.reduc/100)*(Number(sub()) + configlivraison).toFixed(2)).toFixed(2) : Number(Number(paniers?.codepromo.reduc/100)*(Number(subNt()))).toFixed(2)
                                             : `${paniers?.codepromo.reduc?.toFixed(2)}` } € </td> 
                                          </tr> 
                                      )
                                    }
            
                                    <tr>
                                      <td class="f-w-7 font-18">
                                        <h4>Total (TTC) : </h4>
                                      </td>
                                      <td class="f-w-7 font-18">
                                      {paniers?.codepromo ? (<td><b>{paniers.livraison ?  (Number(sub()) + configlivraison - (paniers?.codepromo?.percent ? (paniers?.codepromo.reduc/100)*(Number(sub()) + configlivraison) : paniers?.codepromo.reduc)).toFixed(2)  : subNt(true) } €</b></td>) : (<td><b>{paniers.livraison ?  (Number(sub()) + configlivraison).toFixed(2)  : subNt()} €</b></td>)}
                                      </td>
                                    </tr>
                                  </tbody>

                                  <p  style={{fontSize:14}}><b>Adresse de livraison :</b><br/>
                                        {paniers.client?.lastName} {paniers.client?.firstName}<br/>
                                        {paniers.client?.phoneperso}<br/><br/>
                                        {paniers?.client?.addresspersolocation}<br/>
                                        {paniers?.client?.complementpersolocation}<br/>
                                        {paniers?.client?.citypersolocation} {paniers?.client?.zipcodepersolocation}<br/>
                                        {paniers?.client?.statepersolocation}<br/>
                                        {paniers?.client?.phoneperso}<br/>
                                        </p>
                                </table>


                                {clientSecret ? (
                                  <>
                                    <Elements options={options} stripe={stripePromise}>
                                      <CheckoutForm />
                                    </Elements>
                                    {!hideupdatedel && (
                                    <p style={{display:"flex", flexDirection: "row", justifyContent:"space-between", marginTop: 20, fontSize: 11}}><a href="#" onClick={() => {
                                
                                      firebase.firestore()
                                      .collection("carts").doc(paniers.user.id).update({
                                        status: null,
                                      }).then(() => {
                                        setPanier([])
                                        loadPanier()
                                      }) 
                                  }}>Modifier le panier</a> <a href="#" onClick={() => {
                                    if (confirm("Annuler et vider le panier ?")){
                                      firebase.firestore()
                                      .collection("carts").doc(paniers.user.id).delete().then(() => {
                                        toastr.success("Le panier a été supprimer.")
                                        history.push("/");
                                      })
                                    }
                                  }}>Annuler et vider le panier</a></p>
                                    )}
                                    
                                </>
                                ) : (<>
                                  <p>Chargement, veuillez patientez ...</p>
                                  </>
                                )}
                              </div>
                            </div>
                            )}
                      </>


                      
                    )}
          </>
        ): (
          <p style={{textAlign:'center'}}>Chargement ...</p>
        )}
        
        

        </div>                  
       

        </div> 
      </div>
      </>
      );
}
  export default Panier;
  