import React, { useEffect, useState, useRef } from "react";
import firebase from "../firebase";
import Header from "../components/Header"; 
import HeaderLogin from '../components/HeaderLogin';
import Image from 'react-bootstrap/Image';
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { doc, updateDoc } from "firebase/firestore"; 
     
const storage = firebase.storage();
const db = firebase.firestore();

const Home = () =>{
  const [loadingUser, setLoadingUser] = React.useState(false);
  const [isLog, setIsLog] = React.useState(false);
  const [user, setUser] = React.useState(null);
  const [title, setTitle] = useState(''); 
  const [home, setHome] = useState({}); 
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const fileInputRef = useRef(null); // Référence à l'input caché


  const [size, setSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  useEffect(() => {
    const handleResize = () => {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);


  const loadHome = () => {
    firebase.firestore()
      .collection("config")
      .doc('home')
      .get()
      .then( (docd) => {
        const d = docd.data() 
        console.log('chargement' , d)
        setHome(d)
      }) 
  }

  const getUser = async (uid, cb) => {
    await firebase.firestore()
      .collection("users")
      .doc(uid)
      .get()
      .then(async (docd) => {
        var user = docd.data();
        user.id = docd.id;
        cb(user);
      })
      .catch((error) => {
        cb(null);
      });
  };
  const handleFileChange = (e) => { 
    handleUpload(e.target.files[0]);
 };

 const handleButtonClick = (t) => { 
   setTitle(t)
   fileInputRef.current.click(); // Déclenche le clic sur l'input caché
 };

  useEffect(() => {
    // check if user is login
    loadHome()
    setLoadingUser(true);
    firebase.auth().onAuthStateChanged((u) => {
      if (u) {
        setLoadingUser(true);
        //redirection au compte
        getUser(u.uid, (user) => {
          // console.log("user", user);
          setUser(user);
          firebase.auth().languageCode = "fr"; 
          setLoadingUser(false);
          setIsLog(true);
        });
      } else {
        setLoadingUser(false);
      }
    });
    setTimeout(() => {
      setLoadingUser(false); 
    }, 5000);
  }, []);


  const handleUpload = async (imageFile) => {
    if (!imageFile) {
      alert("Veuillez sélectionner une image !");
      return;
    }

    setUploading(true);

    const storageRef = ref(storage, `home/${title}/${imageFile.name}`);
    const uploadTask = uploadBytesResumable(storageRef, imageFile);

    uploadTask.on('state_changed', 
      (snapshot) => {
        // Progress of the upload
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setUploadProgress(progress);
      }, 
      (error) => {
        console.error("Erreur lors du téléversement:", error);
        setUploading(false);
      }, 
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
          // Enregistrer l'URL dans Firestore
          const configDocRef = doc(db, 'config', 'home');
          let ds = {}
          ds[title] = downloadURL
          await updateDoc(configDocRef, ds);
          // alert('Image téléchargée et enregistrée avec succès !');
          setUploading(false);
          let cn = home
          cn[title] = downloadURL;
          setHome(cn);
        });
      }
    );
  };

  return (
    <> 
    <Header />

    <input 
        type="file" 
        ref={fileInputRef} 
        style={{ display: 'none' }}
        onChange={handleFileChange} 
      />
      {home?.tirage_photo && (
          <>
          <div class="d-md-flex flex-md-equal w-100 my-md-3 pl-md-3 ">
              <a href="/c/tirages" className="nounderline"> 
              <div class="bg-dark mr-md-3 pt-3 px-3 pt-md-5 px-md-5 text-center text-white overflow-hidden">
                <div class="my-3 py-3">
                  <h2 class="display-5">Tirage photo</h2>
                  <p class="lead">Imprimez vos souvenirs illico en format classique.</p>
                </div>
                <div class="bg-light box-shadow mx-auto" style={{width: "80%", height: size.width > 800 ? 300 : 160, borderRadius: "21px 21px 0 0"}}>
        
                <Image src={home?.tirage_photo} style={{borderRadius: "21px 21px 0 0"}} fluid />;
                
                </div>
                {user?.isAdmin && (
                  <>
                    <a href='#' class="btn btn-lg btn-secondary" onClick={(event) => {
                      event.preventDefault();
                      handleButtonClick("tirage_photo");
                    }} >Modifier l'image  (1200 × 630 )</a>
                    {uploading && <p>Progression : {uploadProgress}%</p>}
                  </>
                )}
              </div>
              </a>
              <a href="/c/poster" className="nounderline"> 
              <div class="bg-light mr-md-3 pt-3 px-3 pt-md-5 px-md-5 text-center overflow-hidden">
                <div class="my-3 p-3">
                  <h2 class="display-5">Poster</h2>
                  <p class="lead">Parce qu'il y a des moments inoubliables.</p>
                </div>
                <div class="bg-light box-shadow mx-auto" style={{width: "80%", height: size.width > 800 ? 300 : 180, borderRadius: "21px 21px 0 0"}}>
        
                <Image src={home.poster} style={{borderRadius: "21px 21px 0 0"}} fluid />;
        
                </div>
                {user?.isAdmin && (
                  <>
                    <a href='#' class="btn btn-lg btn-secondary" onClick={(event) => {
                      event.preventDefault();
                      handleButtonClick( "poster");
                    }} >Modifier l'image  (1200 × 630 )</a>
                    {uploading && <p>Progression : {uploadProgress}%</p>}
                  </>
                )}
              </div>
              </a>
            </div>
        
            <div class="d-md-flex flex-md-equal w-100 my-md-3 pl-md-3">
            <a href="/c/magnet-aluminium" className="nounderline"> 
              <div class="bg-light mr-md-3 pt-3 px-3 pt-md-5 px-md-5 text-center overflow-hidden">
                <div class="my-3 p-3">
                  <h2 class="display-5">Photo magnétique</h2>
                  <p class="lead">Imprimez vos magnets personnalisées à coller sur le frigo.</p>
                </div>
                <div class="bg-light box-shadow mx-auto" style={{width: "80%", height: size.width > 800 ? 300 : 200, borderRadius: "21px 21px 0 0"}}>
        
                <Image src={home.magnetics} style={{borderRadius: "21px 21px 0 0"}} fluid />;
                </div>
                {user?.isAdmin && (
                  <>
                    <a href='#' class="btn btn-lg btn-secondary" onClick={(event) => {
                      event.preventDefault();
                      handleButtonClick( "magnetics");
                    }} >Modifier l'image  (1200 × 630 )</a>
                    {uploading && <p>Progression : {uploadProgress}%</p>}
                  </>
                )}
              </div>
              </a>
              <a href="/c/photo-sur-toile" className="nounderline"> 
              <div class="bg-primary mr-md-3 pt-3 px-3 pt-md-5 px-md-5 text-center text-white overflow-hidden">
                <div class="my-3 py-3">
                  <h2 class="display-5">Photo sur toile  (1200 × 630 )</h2>
                  <p class="lead">Embellissez vos photos avec un cadre moderne.</p>
                </div>
                <div class="bg-light box-shadow mx-auto" style={{width: "80%", height: size.width > 800 ? 300 : 200, borderRadius: "21px 21px 0 0"}}>
        
                <Image src={home.toile} style={{borderRadius: "21px 21px 0 0"}} fluid />;
                </div>
                {user?.isAdmin && (
                  <>
                    <a href='#' class="btn btn-lg btn-secondary" onClick={(event) => {
                      event.preventDefault();
                      handleButtonClick( "toile");
                    }} >Modifier l'image (1200 × 630 )</a>
                    {uploading && <p>Progression : {uploadProgress}%</p>}
                  </>
                )}
              </div>
              </a>
            </div>
            </>
      )}
  
      {!isLog && !loadingUser && (<HeaderLogin />)}
    </>
  );
};


export default Home;
