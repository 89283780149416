import Carousel from 'react-bootstrap/Carousel';
import React, { useEffect, useState, useRef } from "react";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { doc, updateDoc } from "firebase/firestore";
import firebase from "../firebase";


const storage = firebase.storage();
const db = firebase.firestore();

function Header() {

  const [user, setUser] = useState(null);
  const [title, setTitle] = useState(''); 
  const [sliders, setSliders] = useState({}); 
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const fileInputRef = useRef(null); // Référence à l'input caché

  const getUser = async (uid, cb) => {
    await firebase.firestore()
      .collection("users")
      .doc(uid)
      .get()
      .then(async (docd) => {
        var user = docd.data();
        user.id = docd.id;
        cb(user);
      })
      .catch((error) => {
        cb(null);
      });
  };

  const loadSlider = () => {
    firebase.firestore()
      .collection("config")
      .doc('sliders')
      .get()
      .then( (docd) => {
        const d = docd.data() 
        // console.log('chargement' , d)
        setSliders(d)
      }) 
  }

  useEffect( () => { 
    loadSlider()
    // check if user is login 
    firebase.auth().onAuthStateChanged((u) => {
      if (u) { 
        //redirection au compte
        getUser(u.uid, (user) => {
          // console.log("user", user);
          setUser(user);
          firebase.auth().languageCode = "fr";  
        });
      }  
    }); 
  }, []);

  const handleFileChange = (e) => { 
     handleUpload(e.target.files[0]);
  };

  const handleButtonClick = (t) => { 
    setTitle(t)
    fileInputRef.current.click(); // Déclenche le clic sur l'input caché
  };

  const handleUpload = async (imageFile) => {
    if (!imageFile) {
      alert("Veuillez sélectionner une image !");
      return;
    }

    setUploading(true);

    const storageRef = ref(storage, `sliders/${title}/${imageFile.name}`);
    const uploadTask = uploadBytesResumable(storageRef, imageFile);

    uploadTask.on('state_changed', 
      (snapshot) => {
        // Progress of the upload
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setUploadProgress(progress);
      }, 
      (error) => {
        console.error("Erreur lors du téléversement:", error);
        setUploading(false);
      }, 
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
          // Enregistrer l'URL dans Firestore
          const configDocRef = doc(db, 'config', 'sliders');
          let ds = {}
          ds[title] = downloadURL
          await updateDoc(configDocRef, ds);
          // alert('Image téléchargée et enregistrée avec succès !');
          setUploading(false);
          let cn = sliders
          cn[title] = downloadURL;
          setSliders(cn);
        });
      }
    );
  };


   return ( 
    <div >
      <input 
        type="file" 
        ref={fileInputRef} 
        style={{ display: 'none' }}
        onChange={handleFileChange} 
      />
      {sliders?.tirage_photo && 
      (
        <Carousel className='d-none d-lg-block'>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={sliders?.tirage_photo}
            alt="First slide"
          />

          <Carousel.Caption>
            <div class="container">
              <div class="carousel-caption text-start">
                <h1>Tirage photo</h1>
  
                  <>
                    <p>
                      Carré, panoramique, normal, grand format et bien plus ...
                    </p>
                    <p>
                      <a class="btn btn-lg btn-primary" style={{background: "rgb(255, 0, 173)", borderColor: "black"}} href="/c/all">
                        J'imprime
                      </a>
                    </p>

                {user?.isAdmin && (
                  <>
                    <a class="btn btn-lg btn-secondary" onClick={() => {
                      handleButtonClick( "tirage_photo");
                    }} >Modifier l'image du slider tirage photo</a>
                    {uploading && <p>Progression : {uploadProgress}%</p>}
                  </>
                )}
                  </> 
              </div>
            </div>
          </Carousel.Caption>
        </Carousel.Item> 

        <Carousel.Item>
          <img
            className="d-block w-100"
            src={sliders['10x15']}
            alt="First slide"
          />

          <Carousel.Caption>
            <div class="container">
              <div class="carousel-caption text-start">
                <h1>10x15</h1>
  
                  <>
                    <p>
                      Format 10x15, à l'unité ou en pack
                    </p>
                    <p>
                      <a class="btn btn-lg btn-primary" style={{background: "rgb(255, 0, 173)", borderColor: "black"}} href="/p/mgrH9rAgvgkGi1WERCxV/1">
                        J'imprime
                      </a>
                    </p>
                    
                {user?.isAdmin && (
                  <>
                    <a class="btn btn-lg btn-secondary" onClick={() => {
                      handleButtonClick( "10x15");
                    }} >Modifier l'image du slider 10x15</a>
                    {uploading && <p>Progression : {uploadProgress}%</p>}
                  </>
                )}
                  </> 
              </div>
            </div>
          </Carousel.Caption>

          </Carousel.Item> 
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={sliders?.poster}
            alt="First slide"
          />

          <Carousel.Caption>
            <div class="container">
              <div class="carousel-caption text-start">
                <h1>Poster</h1>
  
                  <>
                    <p>
                      Parce qu'il y a des moments inoubliables.
                    </p>
                    <p>
                      <a class="btn btn-lg btn-primary" style={{background: "rgb(255, 0, 173)", borderColor: "black"}} href="/c/poster">
                        J'imprime mon poster
                      </a>
                    </p>

                {user?.isAdmin && (
                  <>
                    <a class="btn btn-lg btn-secondary" onClick={() => {
                      handleButtonClick( "poster");
                    }} >Modifier l'image du slider poster</a>
                    {uploading && <p>Progression : {uploadProgress}%</p>}
                  </>
                )}
                  </> 
              </div>
            </div>
          </Carousel.Caption>
        </Carousel.Item> 


      </Carousel>
      )}
     
      
    </div> 
  
   );

}

export default Header;